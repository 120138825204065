import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppointmentService from '../../Services/AppointmentService';
import AuthService from '../../Services/AuthService';
import BookingService from '../../Services/BookingService';
import DateSearchService from '../../Services/DateSearchService';
import { YmdFormat, daysInMonth, formatEnddate, formatStartdate, getDatesByDay, getLastMonday, getMaxTimeByDates, getMinTimeByDates, modifyDays } from '../../Utility/BookingFormatter';
import Events from '../../Utility/Events';
import { formatDateWithoutDay } from '../../Utility/Formatter';
import history from '../../Utility/RouterHistory';
import ThemeHelper from '../../Utility/ThemeHelper';
import { AuthContext } from './AuthContext';
import { CustomThemeContext } from './CustomThemeContext';
import CachingHelper from '../../Utility/CachingHelper';

const DEFAULT_STARTTIME = 420;      //07:00 in the morning
const DEFAULT_ENDTIME = 1030;       //approx. 17:00 afternoon

const DEFAULT_SLOTHEIGHT = 2;
const SLOTHEIGHT_TOTAL_BY_DURATION = 100;

const DEFAULT_DAYRANGE = 7;
const SLIDEABLE_DAYRANGE = 14;

const CONTEXT_DEFAULTS = {
    /** selection props **/
    stage: null,
    passed: [],
    passedOption: [],
    workflow: {},
    workflowStage: {},
    handleGoBack: null,
    handleSetArticle: null,
    handleSetUser: null,
    handleSetLocation: null,
    handleStageOptionClick: null,
    /** general selection **/
    articles: null,
    onlyGroupdates: false,
    users: null,
    location: null,
    booking: null,
    forcePendingBooking: null,
    /** calender props **/
    mindate: null,
    startdate: null,
    columnCount: 5,
    dates: [],
    datesByDay: {},
    dayOptions: [1,3,5,7],
    disablePrevDate: false,
    handleColumnCountChange: null,
    handlePrevDate: null,
    handleNextDate: null,
    getListTimeRange: null,
    handleSubmitBooking: null,
    minTime: DEFAULT_STARTTIME,
    maxTime: DEFAULT_ENDTIME,
    slotHeight: DEFAULT_SLOTHEIGHT,
    loadNextData: null,
    handleLoadMore: null,
    viewType: 'grid',
    dayRange: DEFAULT_DAYRANGE,
};

export const BookingContext = React.createContext(CONTEXT_DEFAULTS);

const BookingContextProvider = (props) => {
    const { children, initialStage, selectedClientNumber } = props;

    const { theme, currentClient, currentPublicClientConfigurations } = useContext(CustomThemeContext);
    const { isAuthenticated, client } = useContext(AuthContext);
    const { t } = useTranslation();

    /** selection props **/
    const [ initialWorkflow, setInitialWorkflow ]   = useState(true);
    const [ stage, setStage ]                       = useState(CONTEXT_DEFAULTS.stage);
    const [ passed, setPassed ]                     = useState(CONTEXT_DEFAULTS.passed);
    const [ passedOption, setPassedOption ]         = useState(CONTEXT_DEFAULTS.passedOption); 
    const [ workflow, setWorkflow ]                 = useState(CONTEXT_DEFAULTS.workflow);
    const [ workflowStage, setWorkflowStage ]       = useState(CONTEXT_DEFAULTS.workflowStage);

    useEffect(() => {
        if (initialWorkflow === true) {
            if (currentClient && (selectedClientNumber === null || currentClient === selectedClientNumber)) {
                if (localStorage.getItem('public-token')) {
                    setInitialWorkflow(false);

                    let workflowPromise;
                    if (isAuthenticated() && client) {
                        workflowPromise = BookingService.workflowAuthenticated();
                    } else {
                        workflowPromise = BookingService.workflowPublic();
                    }
                    
                    workflowPromise.then(res => {
                            const entries = Object.keys(res);
                            if (typeof entries[0] !== 'undefined') {
                                setWorkflow(res);
                                setStage(entries[0]);
                                return res;
                            }
                        })
                        .then(res2 => {
                            /**
                             * check if there is a booking pending
                             */
                            if (isAuthenticated() && client) {
                                let pendingBooking = localStorage.getItem('booking-' + currentPublicClientConfigurations.main_client_number);
                                if (pendingBooking) {
                                    localStorage.removeItem('booking-' + currentPublicClientConfigurations.main_client_number);
                                    pendingBooking = JSON.parse(pendingBooking);
                                    
                                    forcePendingBooking(pendingBooking);
                                    return;
                                }
                            }
                        });
                }
            }
        };
    });

    useEffect(() => {
        if (initialWorkflow === false) {
            if (initialStage) {
                if (Object.entries(workflow).length > 0) {
                    if (typeof workflow[initialStage] !== 'undefined') {
                        if (typeof currentPublicClientConfigurations.booking_deeplinks !== 'undefined') {
                            //that this is not triggered twice, otherwise go-back button has to be clicked twice
                            if (passed.length < 1) {
                                const deeplinks = currentPublicClientConfigurations.booking_deeplinks;
                                if (deeplinks.length > 0 && deeplinks.includes(initialStage)) {
                                    handleStageOptionSet(initialStage, workflow[initialStage]);
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [workflow, currentPublicClientConfigurations]);

    /** general selection props **/
    const [ articles, setArticles ]                 = useState(CONTEXT_DEFAULTS.articles);
    const [ onlyGroupdates, setOnlyGroupdates ]     = useState(CONTEXT_DEFAULTS.onlyGroupdates);
    const [ articleDetails, setArticleDetails ]     = useState(CONTEXT_DEFAULTS.articleDetails);
    const [ users, setUsers ]                       = useState(CONTEXT_DEFAULTS.users);
    const [ location, setLocation ]                 = useState(CONTEXT_DEFAULTS.location);
    const [ locationDetails, setLocationDetails ]   = useState(CONTEXT_DEFAULTS.locationDetails);
    const [ booking, setBooking ]                   = useState(CONTEXT_DEFAULTS.booking);

    const handleGoBack = (e) => {
        let newPassed = passed;
        let newPassedOption = passedOption;

        let prev = newPassed.pop();
        let prevOption = newPassedOption.pop();

        if (prev) {
            setStage(prev);
            setPassed(newPassed);
            setPassedOption(newPassedOption);

            if (typeof prevOption.type !== 'undefined') {
                if (prevOption.type === 'user') {
                    setUsers(CONTEXT_DEFAULTS.users);
                }
                if (prevOption.type === 'article') {
                    setArticles(CONTEXT_DEFAULTS.articles);
                    setOnlyGroupdates(CONTEXT_DEFAULTS.onlyGroupdates);
                    setArticleDetails(CONTEXT_DEFAULTS.articleDetails);
                }
                if (prevOption.type === 'location') {
                    setLocation(CONTEXT_DEFAULTS.location);
                    setLocationDetails(CONTEXT_DEFAULTS.locationDetails);
                }
                if (prevOption.type === 'booking') {
                    setBooking(CONTEXT_DEFAULTS.booking);
                }
            }
        } else {
            history.push('/appointments');
        }
    }

    const getSingleValue = (option) => {
        let value = null;
        if (typeof option.id !== 'undefined') {
            value = option.id;
        }
        return value;
    }

    const getMultiValue = (option) => {
        let values = null;
        if (typeof option.values !== 'undefined') {
            //multiple, groupdates
            values = option.values;
        } else {
            if (typeof option.id !== 'undefined') {
                values = [option.id];
            }
        }
        return values;
    }

    const handleSetArticle = (option) => {
        let onlyGroupdates = false;
        if (typeof option.onlyGroupdates !== 'undefined' && option.onlyGroupdates) {
            onlyGroupdates = true;
        }
        setOnlyGroupdates(onlyGroupdates);
        setArticles(getMultiValue(option));
        setArticleDetails(option);
    }

    const handleSetUser = (option) => {
        setUsers(getMultiValue(option));
    }
    
    const handleSetLocation = (option) => {
        setLocation(getMultiValue(option));
        setLocationDetails(option);
    }

    const handleSetBooking = (option) => {
        let newBooking = null;
        if (typeof option.item !== 'undefined') {
            newBooking = option.item;
            if (articles) {
                newBooking.articles = articles;
            }
        }
        setBooking(newBooking);
        if (!isAuthenticated()) {
            const clNr = AuthService.getActiveClientNumber();
            localStorage.setItem('booking-' + clNr, JSON.stringify(newBooking));
            history.push('/');
            Events.dispatch('msgInfo', t('please-sign-in-or-register-to-proceed'));
        }
    }

    const forcePendingBooking = (booking) => {
        setPassed([]);
        setPassedOption([]);
        setBooking(booking);
        setStage('checkout');
    }

    const handleStageOptionClick = (e, option, ignoreBackLink = false) => {
        setSearchTransaction(false);
        if (typeof option.type !== 'undefined') {
            const { type } = option;
            
            const map = {
                user: handleSetUser,
                article: handleSetArticle,
                location: handleSetLocation,
                booking: handleSetBooking
            };

            if (typeof map[type] === 'function') {
                map[type](option);
            }
        }
        if (typeof option.next !== 'undefined') {
            if (typeof workflow[option.next] !== 'undefined' || ['calendar', 'checkout'].includes(option.next)) {
                let newPassed = passed;
                let newPassedOption = passedOption;
                if (ignoreBackLink === false) {
                    newPassed.push(stage);
                    newPassedOption.push(option);
                    setPassed(newPassed);
                    setPassedOption(newPassedOption);
                }
                setStage(option.next);
            }
        }
        setSearchTransaction(true);
    }

    const handleStageOptionSet = (newStage, option) => {
        if (typeof workflow[newStage] !== 'undefined' || ['calendar', 'checkout'].includes(newStage)) {
            if (typeof option.type !== 'undefined') {
                const { type } = option;
                
                const map = {
                    user: handleSetUser,
                    article: handleSetArticle,
                    location: handleSetLocation,
                    booking: handleSetBooking
                };
    
                if (typeof map[type] === 'function') {
                    map[type](option);
                }
            }

            let newPassed = passed;
            let newPassedOption = passedOption;
            newPassed.push(stage);
            newPassedOption.push(option);
            setPassed(newPassed);
            setPassedOption(newPassedOption);
            setStage(option.next ?? newStage);
        }
    }

    const handleSubmitBooking = (e, data = {}) => {
        let params = {
            user_id: booking.user_id,
            start: booking.begin,
            end: booking.end,
        };
        if (typeof booking.date_id !== 'undefined') {
            //groupdate
            params.date_id = booking.date_id;
            if (typeof booking.article_id !== 'undefined') {
                params.article_id = booking.article_id;
            }
        } else {
            //regular date
            if (typeof booking.articleDetails.id !== 'undefined') {
                params.article_id = booking.articleDetails.id;
            }
            if (typeof booking.article_id !== 'undefined') {
                params.article_id = booking.article_id;
            }
        }
        if (typeof data.description !== 'undefined') {
            params.description = data.description;
        }
        return AppointmentService
                    .create(params);
    }

    const handleSearchFirstFreeSlot = () => {
        if (!loading) {
            clearTimeout(loadDataTimeout);
            loadDataTimeout = setTimeout(() => {
                setLoading(true);
                Events.dispatch('startLoading');
                DateSearchService
                    .first(getSearchFirstParams())
                    .then(res => {
                        const data = res.data;

                        Events.dispatch('doneLoading');
                        setLoading(false);
                    
                        if (typeof data.range !== 'undefined') {
                            const range = data.range;

                            let newStartdate = range.first;
                            if (viewType === 'month') {
                                newStartdate = YmdFormat(newStartdate);
                                newStartdate = newStartdate.substring(0, newStartdate.length - 2);
                                newStartdate = newStartdate + '01';
                            }
                            setStartdate(formatStartdate(newStartdate));
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        Events.dispatch('doneLoading');
                        Events.dispatch('msgInfo', t('no-more-appointments-found'));
                    });
            },50);
        }
    }

    /** calender props **/
    const [ initialCalendar, setInitialCalendar ]   = useState(true);
    const [ mindate, setMindate ]                   = useState(CONTEXT_DEFAULTS.mindate);
    const [ startdate, setStartdate ]               = useState(CONTEXT_DEFAULTS.startdate);
    const [ columnCount, setColumnCount ]           = useState(CONTEXT_DEFAULTS.columnCount);
    const [ dates, setDates ]                       = useState(CONTEXT_DEFAULTS.dates);
    const [ datesByDay, setDatesByDay ]             = useState(CONTEXT_DEFAULTS.datesByDay);
    const [ dayOptions, setDayOptions ]             = useState(CONTEXT_DEFAULTS.dayOptions);
    const [ disablePrevDate, setDisablePrevDate ]   = useState(CONTEXT_DEFAULTS.disablePrevDate);
    const [ minTime, setMinTime ]                   = useState(DEFAULT_STARTTIME);
    const [ maxTime, setMaxTime ]                   = useState(DEFAULT_ENDTIME);
    const [ slotHeight, setSlotHeight ]             = useState(DEFAULT_SLOTHEIGHT);
    const [ viewType, setViewType ]                 = useState(CONTEXT_DEFAULTS.viewType);
    const [ loading, setLoading ]                   = useState(false);
    const [ dayRange, setDayRange ]                 = useState(CONTEXT_DEFAULTS.dayRange);
    const [ searchTransaction, setSearchTransaction ]= useState(true);

    let loadDataTimeout = null;

    const setDefaultViewType = () => {
        if (theme) {
            const { values } = theme.breakpoints;
            const size = ThemeHelper.getBreakpoint(values);

            if (size === 'xs' || size === 'sm') {
                if (size === 'xs') {
                    setViewType('list');
                }
            }
        }
    }

    const setDefaultColumnCounts = () => {
        if (theme) {
            const { values } = theme.breakpoints;
            const size = ThemeHelper.getBreakpoint(values);
            
            if (size === 'xs' || size === 'sm') {
                if (size === 'xs') {
                    setColumnCount(1);
                    setDayOptions([1]);
                }
                if (size === 'sm') {
                    setColumnCount(3);
                    setDayOptions([1,3]);
                }
            } else {
                if (typeof currentPublicClientConfigurations.booking_default_column_count !== 'undefined') {
                    if (dayOptions.includes(currentPublicClientConfigurations.booking_default_column_count)) {
                        setColumnCount(currentPublicClientConfigurations.booking_default_column_count);
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (stage) {
            setWorkflowStage(workflow[stage] ?? {});
        }
        if (stage === 'calendar') {
            let minStartDate = getLastMonday(new Date());

            setSearchTransaction(false);

            setMindate(formatStartdate(minStartDate));
            if (CachingHelper.get('booking-startdate')) {
                minStartDate = CachingHelper.get('booking-startdate');
            }
            setStartdate(formatStartdate(minStartDate));
            setDatesByDay({});

            if (initialCalendar === true) {
                setInitialCalendar(false);

                if (CachingHelper.get('booking-startdate')) {
                    minStartDate = CachingHelper.get('booking-startdate');
                }
                setStartdate(formatStartdate(minStartDate));

                setDefaultViewType();
                setDefaultColumnCounts();
            }

            setTimeout(() => {
                setSearchTransaction(true);
            }, 50);
        } else {
            if (stage !== 'checkout') {
                CachingHelper.remove('booking-startdate');
            }
        }
    }, [stage]);

    useEffect(() => {
        if (typeof workflowStage['options'] !== 'undefined') {
            if (workflowStage['options'].length === 1) {
                const opt = workflowStage['options'][0];
                if (typeof opt['redirect'] !== 'undefined' && opt['redirect']) {
                    handleStageOptionClick({}, opt, true);
                }
            }
        }
    }, [workflowStage]);

    useEffect(() => {
        if (startdate) {
            let newMindate = formatStartdate(new Date());
            if (![1,3].includes(columnCount)) {
                newMindate = formatStartdate(getLastMonday(newMindate));
            }
            setMindate(newMindate);

            if ([5,7].includes(columnCount)) {
                let lastMonday = formatStartdate(getLastMonday(startdate));
                if (lastMonday !== startdate) {
                    setStartdate(lastMonday);
                }
            }
        }
    }, [columnCount]);

    useEffect(() => {
        if (startdate && mindate) {
            if (new Date(startdate) < new Date(mindate)) {
                setStartdate(mindate);
            }
        }
    }, [mindate]);

    useEffect(() => {
        if (startdate) {
            if (startdate <= mindate) {
                setDisablePrevDate(true);
            } else {
                setDisablePrevDate(false);
            }
            
            CachingHelper.set('booking-startdate', startdate);
        }
    }, [columnCount, startdate]);
    
    useEffect(() => {
        if (viewType === 'month') {
            let newStartdate = YmdFormat(startdate);
            newStartdate = newStartdate.substring(0, newStartdate.length - 2);
            newStartdate = newStartdate + '01';

            if (viewType === 'month') {
                const daysInSearch = daysInMonth(newStartdate);
                setColumnCount(daysInSearch);
                setDayRange(daysInSearch);
            }

            setStartdate(formatStartdate(newStartdate));
        } else {
            if (viewType === 'list') {
                setDayRange(SLIDEABLE_DAYRANGE);
            } else {
                if (!dayOptions.includes(columnCount)) {
                    setColumnCount(dayOptions[0]);
                }
                setDayRange(DEFAULT_DAYRANGE);
            }
        }
    }, [viewType]);

    useEffect(() => {
        setDateArray();
    }, [datesByDay, viewType, columnCount]);

    useEffect(() => {
        if (initialCalendar === false) {
            if (stage === 'calendar') {
                if (searchTransaction) {
                    loadData();
                }
            }
        }
    }, [startdate, dayRange, articles, users, location, searchTransaction]);

    useEffect(() => {
        if (articleDetails) {
            if (typeof articleDetails.duration !== 'undefined') {
                const { duration } = articleDetails;
                let newSlotHeight = SLOTHEIGHT_TOTAL_BY_DURATION / duration;
                setSlotHeight(newSlotHeight);
            }
        }
    }, [articleDetails]);


    const getSearchParams = () => {
        let params = {
            date: startdate,
            enddate: formatEnddate(modifyDays(startdate, dayRange - 1)),
            duration: 30,
        };
        if (users) {
            params.user_id = users;
        }
        if (location) {
            params.location_id = location;
        }
        if (articles) {
            if (typeof articles !== 'string' || articles !== 'all') {
                params.groupdate = articles;
            }
            params.date_type = 'groupdate';
            params.find_all  = (onlyGroupdates ? 0 : 1);
        }
        if (articleDetails) {
            if (typeof articleDetails.duration !== 'undefined') {
                params.duration = articleDetails.duration;
            }
            if (typeof articleDetails.id !== 'undefined') {
                params.article_id = articleDetails.id;
            }
        }
        return params;
    }

    const getSearchFirstParams = () => {
        const startdateParam = formatStartdate(modifyDays(startdate, columnCount));
        return {
            ...getSearchParams(),
            date: startdateParam,
            startdate: startdateParam,
            enddate: formatEnddate(modifyDays(startdate, columnCount + 7)),
        };
    }

    const loadData = () => {
        if (!loading) {
            clearTimeout(loadDataTimeout);
            loadDataTimeout = setTimeout(() => {
                setLoading(true);
                Events.dispatch('startLoading');
                DateSearchService
                    .search(getSearchParams())
                    .then(res => {
                        const data = res.data;
                        let newDatesByDay = getDatesByDay(data);
                        if (viewType === 'list') {
                            newDatesByDay = {...datesByDay, ...newDatesByDay};
                        }
                        setDatesByDay(newDatesByDay);
                        setMinTime(getMinTimeByDates(data, DEFAULT_STARTTIME));
                        setMaxTime(getMaxTimeByDates(data, DEFAULT_ENDTIME));                        
                        Events.dispatch('doneLoading');
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        Events.dispatch('doneLoading');
                        Events.dispatch('msgInfo', t('no-more-appointments-found'));
                    });
            }, 50);
        }
    }

    /**
     * method for scrolling, currently not supported, in cause of multi triggers
     */
    const loadNextData = () => {
        if (viewType === 'list') {
            if (false && !loading) {
                // setStartdate(formatStartdate(modifyDays(startdate, dayRange - 1)));
            }
        }
    }

    const setDateArray = () => {
        if (startdate) {
            if (!['list', 'month'].includes(viewType)) {
                let date = YmdFormat(startdate);
                let count = 0;
                let newDates = [];
                while (count < columnCount) {
                    newDates.push(date);
                    date = modifyDays(date, 1);
                    count++;
                }
                setDates(newDates);
            } else {
                //show only set dates
                setDates(Object.keys(datesByDay));
            }
        }
    }

    const handlePrevDate = (e) => {
        let modDays = columnCount;
        if ([5,7].includes(columnCount)) {
            modDays = 7;
        }

        if (viewType === 'month') {
            const daysInSearch = daysInMonth(startdate, -1);
            setColumnCount(daysInSearch);
            setDayRange(daysInSearch);
            
            modDays = daysInSearch;
        }

        let newStartdate  = modifyDays(startdate, -modDays); 
        if (parseInt(newStartdate.replaceAll('-', '')) < parseInt(YmdFormat(new Date()).replaceAll('-',''))) {
            newStartdate = YmdFormat(new Date());
        }
        if ([5,7].includes(columnCount)) {
            newStartdate = getLastMonday(newStartdate);
        }
        if (viewType === 'month') {
            newStartdate = newStartdate.substring(0, newStartdate.length - 2);
            newStartdate = newStartdate + '01';
        }
        setStartdate(formatStartdate(newStartdate));
    }

    const handleNextDate = (e) => {
        let modDays = columnCount;
        if ([5,7].includes(columnCount)) {
            modDays = 7;
        }
        let newStartdate  = modifyDays(startdate, modDays);
        if ([5,7].includes(columnCount)) {
            newStartdate = getLastMonday(newStartdate);
        }
        
        if (viewType === 'month') {
            newStartdate = newStartdate.substring(0, newStartdate.length - 2);
            newStartdate = newStartdate + '01';

            const daysInSearch = daysInMonth(newStartdate);
            setColumnCount(daysInSearch);
            setDayRange(daysInSearch);
        }
        setStartdate(formatStartdate(newStartdate));
    }

    const handleSetByDate = (e, newStartdate) => {
        setDayRange(1);
        setViewType('grid');

        setTimeout(() => {
            setColumnCount(1);
        }, 100);

        if (parseInt(newStartdate.replaceAll('-', '')) < parseInt(YmdFormat(new Date()).replaceAll('-',''))) {
            newStartdate = YmdFormat(new Date());
        }
        setStartdate(formatStartdate(newStartdate));
    }

    const handleLoadMore = (e) => {
        if (viewType === 'list') {
            const newStartdate  = modifyDays(startdate, dayRange); 
            setStartdate(formatStartdate(newStartdate));
        }
    }

    const getListTimeRange = () => {
        if (viewType === 'list') {
            if (startdate && mindate) {
                return formatDateWithoutDay(mindate) + ' - ' + formatDateWithoutDay(modifyDays(startdate, dayRange - 1));
            }
        }
        return "";
    }

    const contextValues = {
        /** selection props **/
        stage: stage,
        passed: passed,
        workflow: workflow,
        workflowStage: workflowStage,
        handleGoBack: handleGoBack,
        handleSetArticle: handleSetArticle,
        handleSetUser: handleSetUser,
        handleSetLocation: handleSetLocation,
        handleStageOptionClick: handleStageOptionClick,
        /** general selection props**/
        articles: articles,
        articleDetails: articleDetails,
        users: users,
        location: location,
        locationDetails: locationDetails,
        booking: booking,
        forcePendingBooking: forcePendingBooking,
        /** calendar props **/
        currentPublicClientConfigurations: currentPublicClientConfigurations,
        mindate: mindate,
        startdate: startdate,
        columnCount: columnCount,
        dates: dates,
        datesByDay: datesByDay,
        dayOptions: dayOptions,
        disablePrevDate: disablePrevDate,
        handleColumnCountChange: setColumnCount,
        handlePrevDate: handlePrevDate,
        handleNextDate: handleNextDate,
        handleSetByDate: handleSetByDate,
        handleSubmitBooking: handleSubmitBooking,
        minTime: minTime,
        maxTime: maxTime,
        slotHeight: slotHeight,
        loadNextData: loadNextData,
        handleLoadMore: handleLoadMore,
        getListTimeRange: getListTimeRange,
        viewType: viewType,
        setViewType: setViewType,
        handleSearchFirstFreeSlot: handleSearchFirstFreeSlot,
    };

    return <BookingContext.Provider value={contextValues}>
        {children}
    </BookingContext.Provider>;
}

export default BookingContextProvider;